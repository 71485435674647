<template>
    <div>
        <el-form ref="form" :model="form" :rules="rules">
            <el-row  :gutter="20">
                <el-col :sm="12">
                    <el-form-item prop="from">
                        <el-time-select
                            :placeholder="columns.from.title"
                            v-model="form.from"
                            :picker-options="{
                            start: min,
                            step: '00:30',
                            end: max
                            }">
                        </el-time-select>
                    </el-form-item>
                </el-col>
                <el-col :sm="12">
                    <el-form-item prop="to">
                        <el-time-select
                            :placeholder="columns.to.title"
                            v-model="form.to"
                            :picker-options="{
                            start: min,
                            step: '00:30',
                            end: max,
                            minTime: form.from
                            }">
                        </el-time-select>
                    </el-form-item>
                </el-col>
                <el-col :span="24">
                    <el-form-item prop="room_id">
                        <el-select v-model="form.room_id" :placeholder="columns.room_id.title">
                            <el-option
                            v-for="item in rooms"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
             </el-row>   
                
           
            <el-row class="butm text-right">
                <el-button type="success" :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)">Сохранить</el-button>
                <el-button @click="resetForm('form')">Отмены</el-button>
            </el-row>
        </el-form>
    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex'
    export default {
        props: {
            specialty: {type: Number, default: null},
            doctor: {type: Number, default: null},
            day: {type: Number, default: 1},
            drawerCreate: {type: Boolean, default: true}
        },
        data() {
            return {
                loadingButton: false,
                value: '',
                form: {}
            }
        },
        created(){
            this.form = JSON.parse( JSON.stringify( this.model )); 
            this.form.specialty_id = this.specialty 
            this.form.doctor_id = this.doctor 
            this.form.day = this.day
        },  
        watch: {
            drawerCreate: {
                handler: function () {
                    if (this.drawerCreate){
                        this.form.specialty_id = this.specialty 
                        this.form.doctor_id = this.doctor 
                        this.form.day = this.day
                    }
                },
                deep: true
            },
        },      
        computed:{
            ...mapGetters({
                rooms: 'rooms/list',
                hours: 'hours/list',
                model: 'workTimes/model',
                columns: 'workTimes/columns',
                rules: 'workTimes/rules',
            }),
            max: function() {
                if(this.hours === 'undefined' && this.hours == null)
                    return null;
                else{
                    let times = this.hours.map((item) => {
                        return item.time;
                    })
                    
                    var maxTime = times[times.length-1];
                    return this.oneHourPlus(maxTime);
                }
            },
            min: function() {
                if(this.hours === 'undefined' && this.hours == null)
                    return null;
                else{
                    let times = this.hours.map((item) => {
                        return item.time;
                    })
                    
                    var minTime = times[0];
                    return minTime;
                }
            }
        },
        async mounted(){
            
            if (this.rooms && this.rooms.length === 0)
                await this.loadRooms();
                
            if (this.hours && this.hours.length === 0)
                await this.loadHours();
        },
        methods: {
            ...mapActions({
                save: 'workTimes/store',
                loadRooms: 'rooms/index',
                loadHours: 'hours/index',
            }),
            submit(close = true){
                this.$refs['form'].validate((valid) => {
                    if (valid){
                        this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.loadingButton = false;
                                this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
                                if (close){
                                    this.$refs['form'].resetFields();
                                    this.form = {};
                                    this.$emit('c-close',{reload: true, drawer: 'drawerCreate'});
                                }
                                    
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                if(err.code == 422){
                                    this.$notify({
                                        title: 'Ошибка ',
                                        type: "error",
                                        message: err.message
                                    });
                                }
                                else{
                                    this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                                }
                            });
                    }
                });
            },
            oneHourPlus(time){
                var result = new Date()
                if(time)
                    result.setHours(time.split(":")[0],time.split(":")[1])
                result.setHours(result.getHours() + 1);
                return this.checkTime(result.getHours()) + ':' + this.checkTime(result.getMinutes())
            },
            checkTime(i) {
                return (i < 10) ? "0" + i : i;
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form = {};
                this.$emit('c-close',{drawer: 'drawerCreate'});
            },
        },        
    }
</script>